import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="players"
export default class extends Controller {
  static targets = ["player_form"]
  static values = {
    match: Object,
    matchId: Number,
    report: Object,
    reportId: Number
  }

  connect() {
    console.log("Players controller")
  }

  async getPlayer(event) {
    const { value } = event.target
    if (!!value) {
      get(
        `/routes/${this.matchValue.route_id}/matches/${this.matchValue.id}/reports/${this.reportIdValue}/players/${Number(value)}`,
        {responseKind: "turbo-stream"}
      )
    }
  }

  getPositionProfiles(event) {
    const { value, name, id} = this.element
    const query = {
      "q[position_id_eq]": value,
      target_name: name.replace(/position_id/, "profile_tokens"),
      target_id: id.replace(/position_id/, "profile_tokens")
    }

    get("/players/elements", {
      responseKind: "turbo-stream",
      query: query
    })
  }
}
