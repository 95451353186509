// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"

import "./controllers"

// import "@popperjs/core"

import "bootstrap"

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap
