import { Controller } from "@hotwired/stimulus"
import { get, patch } from "@rails/request.js"

// Connects to data-controller="forms"
export default class extends Controller {
  static targets = ["input", "checkbox", "form"]
  static values = { model: String, url: String, method: String, object: Object }

  initialize() {
    console.log("initialize forms controller")
    // this.element.addEventListener("turbo:submit-end", (e) => {
    //   e.preventDefault()
    //   this.next(e)
    // })
  }

  next(event) {
    console.log("next")
    event.preventDefault()

    if (event.detail.success) {
      let { url } = event.detail.fetchResponse.response
      history.pushState({ data_turbo_history: "replace" }, "", url)
      Turbo.navigator.history.push(url)
    }
  }

  showSectionBySelectedValue(event) {
    console.log("show section by selected value")
    event.preventDefault()

    this.toggle(event)
  }

  toggle(event) {
    console.log("toggle")
    event.preventDefault()

    const id = Object.keys(event.params).length ? event.params.targetId : `${event.target.value.toLowerCase()}`
    const element = document.getElementById(id)
    const checkboxes = element.getElementsByClassName('form-check-input')
    // if (element.classList.contains('d-none')) element.classList.remove("d-none")
    // else element.classList.add("d-none");

    if (element && element.classList.contains('d-none')) {
      element.classList.remove("d-none")
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].removeAttribute('disabled')
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].setAttribute('disabled', true)
      }
      event.target.name = event.target.name.replace(event.params.modelName, '')
      element && element.classList.add("d-none");
    }
  }

  levelsToggle(event) {
    console.log("levels toggle")
    event.preventDefault()

    const id = Object.keys(event.params).length ? event.params.targetId : `${event.target.value.toLowerCase()}`
    const element = document.getElementById(id)

    if ((event.params && event.params.level && event.params.level === 3) || event.target.value === "3") {
      if (element.classList.contains('d-none')) element.classList.remove('d-none')
      else element.classList.add('d-none')
    }
  }

  closeToggle(event) {
    console.log("close toggle")
    event.preventDefault()

    const { open, close } = event.params
    const { checked } = event.target
    const label = this.element.getElementsByTagName('label')[0]

    label.innerHTML = checked ? open : close
  }

  disableOthers(event) {
    console.log("disable other")
    event.preventDefault()

    const elements = Array.from(document.querySelectorAll(`.${event.params.targetClass}`))
    const ids = elements.map((e) => e.id)
    const aux = ids.filter((id, index) => ids.indexOf(id) !== index)
    const set = [...new Set(aux)]
    const repeated = document.querySelectorAll(`#${set[0]}`)

    if (repeated.length > 1) {
      const last = repeated[repeated.length - 1]
      const newId = `${event.params.targetClass}-${Date.now()}`
      last.id = newId
      last.nextElementSibling.children[0].setAttribute("for", newId)
    }

    for (const element of elements) {
      if (element.id !== event.target.id) {
        if (event.type === "change") {
          if (event.target.checked) {
            element.checked = false
            element.setAttribute("disabled", "disabled")
          }
          else element.removeAttribute("disabled")

          if (element.id === event.target.id && element.hasAttribute("disabled")) element.removeAttribute("disabled")
        }

        if (event.type === "click" || event.type === "update") {
          if (elements.some((e) => e.checked)) {
            const checkedElement = elements.filter((e) => e.checked)[0]

            if (checkedElement !== element && elements.some((e) => e.checked)) {
              element.checked = false
              element.setAttribute("disabled", "disabled")
            }
          } else element.removeAttribute("disabled")
        }
      }
    }
  }

  submit(event) {
    event.preventDefault()

    const { reportableUrl } = event.params
    const { form, value } = event.target
    const { action } = form
    const url = new URL(action)
    const { pathname } = url
    const formData = new FormData(form)
    const entries = Object.fromEntries(formData.entries())
    const params = new URLSearchParams(Object.entries(entries).filter(([_, v]) => !!v))
    const playerSelector = document.getElementById("report_reportable_id")

    clearTimeout(this.timeout)

    this.timeout = setTimeout(async () => {
      if (reportableUrl) {
        const { ok } = await patch(`${reportableUrl}`, { responseKind: "turbo-stream", body: formData })
        if (!ok) alert("Error")
      } else {
        const { ok } = await get(`${pathname}?${params}`, { responseKind: "turbo-stream", formData })
        if (!ok) alert("Error")
      }

      if (playerSelector && playerSelector.tagName === "select") playerSelector.parentNode.classList.add("d-none")
    }, 200)

    // event.target.form.requestSubmit()
  }

  toggleCaret(event) {
    console.log("toggle caret")
    event.preventDefault()

    const { target } = event
    if (!target) return

    if (target.localName === "span") {
      target.classList.toggle("bi-chevron-right")
      target.classList.toggle("bi-chevron-down")
    } else {
      const span = target.parentNode.getElementsByTagName("span")[0]
      if (!span) return

      span.classList.toggle("bi-chevron-right")
      span.classList.toggle("bi-chevron-down")
    }
  }

  async search(event) {
    console.log("search")
    event.preventDefault()

    const { action } = this.formTarget
    const data = new FormData(this.formTarget)
    const params = new URLSearchParams(Object.fromEntries(data.entries()))

    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      const { ok } = await get(`${action}&${params}`, { responseKind: "turbo-stream", data })
      // history.pushState({ data_turbo_history: "replace" }, {}, `${action}&${params}`)
      // this.formTarget.requestSubmit()
    }, 200)
  }
}
