import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"

// Connects to data-controller="matches"
export default class extends Controller {
  connect() {}

  getLeagues() {
    const { value, name, id} = this.element
    const query = {
      "q[country_eq]": value,
      target_name: name.replace(/country/, "league_id"),
      target_id: id.replace(/country/, "league_id"),
      target_home_name: name.replace(/country/, "home_id"),
      target_home_id: id.replace(/country/, "home_id"),
      target_away_name: name.replace(/country/, "away_id"),
      target_away_id: id.replace(/country/, "away_id")
    }

    get("/routes/elements", {
      responseKind: "turbo-stream",
      query: query
    })
  }

  getClubs() {
    const { value, name, id} = this.element
    const countryElement = document.querySelector(`#${id.replace(/league_id/, "country")}`)
    let query = {
      "q[league_id_eq]": value,
      target_home_name: name.replace(/league_id/, "home_id"),
      target_home_id: id.replace(/league_id/, "home_id"),
      target_away_name: name.replace(/league_id/, "away_id"),
      target_away_id: id.replace(/league_id/, "away_id")
    }
    if (!!countryElement.value) query["q[country_eq]"] = countryElement.value

    get("/routes/elements", {
      responseKind: "turbo-stream",
      query: query
    })
  }
}
