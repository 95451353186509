import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="empty"
export default class extends Controller {
  static targets = ["list", "table", "empty"]

  connect() {
    this.observer = new MutationObserver(this.update.bind(this))

    this.observer.observe(this.element, {
      childList: true,
      attributes: false,
      subtree: true
    })

    this.update()
  }

  disconnect() {
    this.observer.disconnect()
  }

  update() {
    console.log(this.listTarget)
    if (this.listTarget.children.length > 0) {
      this.tableTarget.classList.remove("d-none")
      this.emptyTarget.classList.add("d-none")
    } else {
      this.tableTarget.classList.add("d-none")
      this.emptyTarget.classList.remove("d-none")
    }
  }
}
