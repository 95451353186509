import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"
import { get } from "@rails/request.js"

// Connects to data-controller="selectable"
export default class extends Controller {
  static values = {
    allowDeselect: Boolean,
    closeOnSelect: Boolean,
    disabled: Boolean,
    hideSelected: Boolean,
    placeholder: String,
    searchable: Boolean,
    url: String,
  }

  connect() {
    this.select = new SlimSelect({
      select: this.element,
      settings: {
        closeOnSelect: this.closeOnSelectValue || true,
        searchText: 'Lo sentimos, no hay nada que ver aquí.',
        placeholderText: this.placeholderValue || 'Selecciona valor',
        hideSelected: this.hideSelectedValue || true,
        allowDeselect: this.allowDeselectValue || false,
        disabled: this.disabledValue || false,
      }
    })

    if (this.searchableValue) {
      const form = this.element.closest('form')
      const formData = new FormData(form)
      const entries = Object.fromEntries(formData.entries())
      const params = new URLSearchParams(Object.entries(entries).filter(([_, v]) => !!v))

      this.select.events.search = async (search, currentData) => {
        if (search) params.append('q[name_cont]', search)
        try {
          const request = await get(`${this.urlValue}?${params}`, { responseKind: 'json' })
          const { ok, json } = await request
          if (await ok) {
            const { data } = await json
            return data.map((item) => ({ text: item.name, value: item.id }))
          }
        } catch (error) {
          return [{ text: 'Lo sentimos, ocurrió un error.' }]
        }
      }
    }
  }

  disconnect() {
    this.select.destroy()
  }

  allowDeselection(event) {
    event.stopImmediatePropagation()
    this.select.settings.allowDeselect = !!event.target.value
  }
}
