import Flatpickr from "stimulus-flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es";

// Connects to data-controller="flatpickr"
export default class extends Flatpickr {
  static values = { input: String, mode: String }

  connect() {
    super.connect()
    this.config.locale = { ...Spanish, firstDayOfWeek: 1 }
    // this.config = {
    //   maxDate: "today",
    //   dateFormat: "d/m/Y",
    //   allowInput: true
    // }
  }

  disconnect() {
    // this.fp.clear()
  }

  change() {
    // this.element.form.submit()
  }

  decomposeDate(date) {
    return date.split("/")
  }

  formatDateFrom(date) {
    const res = this.decomposeDate(date)
    return `${res[2]}-${res[0]}-${res[1]}`
  }

  showSelectedDates(event) {
    event.preventDefault()
    const selectedDatesElement = document.getElementById("selected-dates")
    const datelistElement = document.getElementById("datelist")

    if (selectedDatesElement.classList.contains("d-none")) selectedDatesElement.classList.remove("d-none")

    const dates = [...new Set(event.target.value.split(","))]
    const date = dates[dates.length - 1]

    const li = document.createElement("li")
    const dateContent = document.createElement("span")
    const removeLink = document.createElement("span")

    li.classList.add("p-2", "border", "border-primary", "rounded", "me-1", "mb-1")
    li.dataset.flatpickrIndexParam = dates.length - 1
    li.dataset.action = "click->flatpickr#doNotShow"

    dateContent.innerHTML = date
    removeLink.innerHTML = `<a href='#' data-action="click->flatpickr#removeDate" class="float-end" data-flatpickr-date-param="${date}" data-flatpickr-index-remove-param="${dates.indexOf(date)}">x</a>`

    li.append(dateContent, removeLink)

    datelistElement.append(li)
  }

  removeDate(event) {
    event.preventDefault()
    this.doNotShow(event)

    const dates = document.getElementById("datelist").children
    const { indexRemove } = event.params

    if (dates[indexRemove]) dates[indexRemove].remove()
  }

  doNotShow(event) {
    event.preventDefault()
    this.fp.destroy()
  }
}
